import baseFetch, { createParamString } from 'common/baseFetch';
import type { CrowdTimestep } from 'types/crowd';
import type { Permission } from 'types/permissions';
import type { Units } from 'types/units';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';

// TODO: Replace type casting as any when adjusting related fetches to improve types

export const fetchSurfForecast = ({
  corrected,
  days,
  id,
  intervalHours,
  kbygQueryParams,
  noCache = 'false',
  startDate,
  units,
}: {
  corrected?: boolean;
  days?: number;
  id: string;
  intervalHours?: number;
  kbygQueryParams?: Record<string, string | boolean>;
  noCache?: string;
  startDate?: string;
  units?: Partial<{
    waveHeight: Units['waveHeight'] | null;
    swellHeight: Units['swellHeight'] | null;
  }>;
}) => {
  let cacheEnabled = !!units?.waveHeight;
  const headers: HeadersInit = {};
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/surf?${createParamString({
      ...kbygQueryParams,
      cacheEnabled,
      corrected,
      days,
      intervalHours,
      spotId: id,
      start: startDate,
      'units[waveHeight]': units?.waveHeight ?? null,
    })}`,
    {
      headers,
    },
  );
};

interface FetchLiveSurfForecastParams {
  id: string;
  kbygQueryParams?: Record<string, string | boolean>;
  noCache?: boolean;
  units: Units;
}

export function fetchLiveSurfForecast({
  id,
  kbygQueryParams,
  noCache = false,
  units,
}: FetchLiveSurfForecastParams) {
  const headers = new Headers();
  if (noCache) {
    headers.set('Cache-Control', 'no-cache');
  }

  return baseFetch<any>(
    `/kbyg/spots/live/surf?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      'units[waveHeight]': units?.waveHeight ?? null,
    })}`,
    {
      headers,
    },
  );
}

export const fetchSwellsForecast = ({
  corrected,
  days,
  id,
  intervalHours,
  kbygQueryParams,
  noCache = 'false',
  startDate,
  units,
}: any) => {
  let cacheEnabled = !!units?.swellHeight;
  const headers: HeadersInit = {};
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/swells?${createParamString({
      ...kbygQueryParams,
      cacheEnabled,
      corrected,
      days,
      intervalHours,
      spotId: id,
      start: startDate,
      'units[swellHeight]': units?.swellHeight ?? null,
    })}`,
    {
      headers,
    },
  );
};

export const fetchTideForecast = ({
  id,
  days,
  kbygQueryParams,
  startDate,
  units,
  noCache = 'false',
}: any) => {
  let cacheEnabled = !!units?.tideHeight;
  const headers: HeadersInit = {};
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/tides?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      days,
      start: startDate,
      cacheEnabled,
      'units[tideHeight]': units?.tideHeight ?? null,
    })}`,
    {
      headers,
    },
  );
};

export const fetchWeatherForecast = ({
  id,
  days,
  intervalHours,
  corrected,
  kbygQueryParams,
  units,
  noCache = 'false',
}: any) => {
  let cacheEnabled = !!units?.temperature;
  const headers: HeadersInit = {};
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/weather?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      days,
      intervalHours,
      corrected,
      cacheEnabled,
      'units[temperature]': units?.temperature ?? null,
    })}`,
    {
      headers,
    },
  );
};

export const fetchSunlightTimes = ({ id, days, kbygQueryParams, intervalHours, startDate }: any) =>
  baseFetch(
    `/kbyg/spots/forecasts/sunlight?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      days,
      intervalHours,
      start: startDate,
    })}`,
  );

export const fetchWindForecast = ({
  id,
  days,
  intervalHours,
  kbygQueryParams,
  units,
  correctedWind = false,
  noCache = 'false',
  startDate,
}: any) => {
  const headers: HeadersInit = {};
  let cacheEnabled = !!units?.windSpeed;
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/wind?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      days,
      intervalHours,
      corrected: correctedWind,
      cacheEnabled,
      'units[windSpeed]': units?.windSpeed ?? null,
      start: startDate,
    })}`,
    {
      headers,
    },
  );
};

export const fetchRatingForecast = ({
  corrected,
  days,
  id,
  intervalHours,
  kbygQueryParams,
  noCache = false,
  ratingsAlgorithm,
  ratingsEndpoint,
  startDate,
}: any) => {
  let cacheEnabled = true;
  const headers: HeadersInit = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(
    `/kbyg/spots/forecasts/rating?${createParamString({
      ...kbygQueryParams,
      spotId: id,
      days,
      intervalHours,
      ratingsAlgorithm,
      ratingsEndpoint,
      corrected,
      start: startDate,
      cacheEnabled,
    })}`,
    {
      headers,
    },
  );
};

export const fetchRegionalConditionForecast = ({
  id,
  days,
  corrected,
  noCache = 'false',
  startDate,
}: any) => {
  const headers: HeadersInit = {};
  if (noCache.toLowerCase() === 'true') {
    headers['Cache-Control'] = 'no-cache';
  }
  return baseFetch(
    `/kbyg/regions/forecasts/conditions?${createParamString({
      subregionId: id,
      days,
      corrected,
      start: startDate,
    })}`,
    {
      headers,
    },
  );
};

export const fetchTaxononmySwellEvents = (id: any, type: any) =>
  baseFetch(`/kbyg/events/taxonomy?${createParamString({ id, type })}`);

export const fetchSwellEventById = ({
  id,
  cookies,
  isServer = false,
}: {
  id: string;
  cookies: Record<string, string>;
  isServer?: boolean;
}): Promise<{
  event: {
    updates: { content: string }[];
  };
}> =>
  baseFetch(`/feed/events/${id}?`, { cookies, headers: { ...addCustomUserAgentHeader(isServer) } });

export const fetchConsistencyForecast = ({
  cameraId,
  days,
  id,
  intervalHours,
  kbygQueryParams,
  noCache = false,
  waveCountEndpoint,
}: {
  cameraId?: string;
  days?: number;
  id: string;
  intervalHours?: number;
  kbygQueryParams?: Record<string, string | boolean>;
  noCache?: boolean;
  waveCountEndpoint?: string;
}) => {
  const headers: HeadersInit = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
  }
  return baseFetch(
    `/kbyg/spots/forecasts/consistency?${createParamString({
      ...kbygQueryParams,
      cameraId,
      days,
      intervalHours,
      spotId: id,
      waveCountEndpoint,
    })}`,
    {
      headers,
    },
  );
};

export const fetchSwellSpectraForecast = ({
  days,
  id,
  intervalHours,
  kbygQueryParams,
  startDate,
  noCache = false,
}: any) => {
  const headers: HeadersInit = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
  }
  return baseFetch(
    `/kbyg/spots/forecasts/spectra?${createParamString({
      ...kbygQueryParams,
      days,
      intervalHours,
      spotId: id,
      start: startDate,
    })}`,
    {
      headers,
    },
  );
};

export interface FetchCrowdForecastParams {
  cameraId?: string;
  days?: number;
  intervalHours?: number;
  lineupEndpoint?: string;
  noCache?: boolean;
  ratingsAlgorithm?: string;
  spotId: string;
  start?: string;
}

export interface FetchCrowdForecastResponse {
  data: {
    predicted: CrowdTimestep[];
    observed: CrowdTimestep[];
  };
  permissions: {
    data: Permission[];
    violations: any[];
  };
}

export const fetchCrowdForecast = async ({
  cameraId,
  days = 1,
  intervalHours = 1,
  lineupEndpoint,
  noCache = false,
  ratingsAlgorithm,
  spotId,
  start,
}: FetchCrowdForecastParams): Promise<FetchCrowdForecastResponse> => {
  const headers: HeadersInit = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
  }
  return baseFetch(
    `/kbyg/spots/forecasts/crowd?${createParamString({
      cameraId,
      days,
      intervalHours,
      lineupEndpoint,
      ratingsAlgorithm,
      spotId,
      start,
    })}`,
    {
      headers,
    },
  );
};
