import { nrNoticeError } from '@surfline/web-common';

import {
  fetchConsistencyForecast,
  fetchLiveSurfForecast,
  fetchRatingForecast,
  fetchRegionalConditionForecast,
  fetchSunlightTimes,
  fetchSurfForecast,
  fetchSwellSpectraForecast,
  fetchSwellsForecast,
  fetchTideForecast,
  fetchWeatherForecast,
  fetchWindForecast,
} from 'common/api/kbyg';
import { getKbygQueryParams } from 'hooks/useConstructSpotPageURL';
import { GraphDataError } from 'types/graphs';
import { Units } from 'types/units';

// Define types for the action parameters
type ActionType = string;
type SuccessType = string;
type FailureType = string;

// Define the props interface for the baseFetchGraphForecast function
interface BaseFetchGraphForecastProps {
  id: string;
  days: number;
  intervalHours: number;
  units?: Partial<Units>;
  desired16DayInterval?: number;
  isGraphUpdates?: boolean;
  isPremium?: boolean;
  startDate?: string;
  cameraId?: string;
  query?: Record<string, string | boolean | string[]>;
}

// Define the fetch function type
type FetchFunction = (params: any) => Promise<any>;

// Define the dispatch function type
type Dispatch = (action: any) => void;

const baseFetchGraphForecast =
  (action: ActionType, success: SuccessType, failure: FailureType, fetch: FetchFunction) =>
  ({
    id,
    days,
    intervalHours,
    units,
    desired16DayInterval = intervalHours,
    isGraphUpdates = false,
    query = {},
    isPremium = false,
    startDate,
    cameraId,
  }: BaseFetchGraphForecastProps) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: action });
    try {
      const {
        corrected,
        correctedRating,
        correctedWind,
        crowdModelName,
        lineupEndpoint,
        noCache,
        ratingsAlgorithm,
        ratingsEndpoint,
        waveCountEndpoint,
      } = query as Record<string, string | boolean>;

      const kbygQueryParams = getKbygQueryParams(query);

      const response = await fetch({
        cameraId,
        corrected,
        correctedRating,
        correctedWind,
        crowdModelName,
        days,
        id,
        intervalHours,
        kbygQueryParams,
        lineupEndpoint,
        noCache,
        ratingsAlgorithm,
        ratingsEndpoint,
        startDate,
        units,
        waveCountEndpoint,
      });

      dispatch({
        type: success,
        intervalHours,
        desired16DayInterval,
        response,
        isGraphUpdates,
        isPremium,
      });
    } catch (error: any) {
      if (error.statusCode === 400) {
        nrNoticeError(error, {});
      }

      dispatch({
        type: failure,
        error: {
          message: error.message || 'Something went wrong here.',
          statusCode: error.statusCode,
        } as GraphDataError,
      });
    }
  };

export const FETCH_GRAPH_CONDITION_FORECAST = 'FETCH_GRAPH_CONDITION_FORECAST';
export const FETCH_GRAPH_CONDITION_FORECAST_SUCCESS = 'FETCH_GRAPH_CONDITION_FORECAST_SUCCESS';
export const FETCH_GRAPH_CONDITION_FORECAST_FAILURE = 'FETCH_GRAPH_CONDITION_FORECAST_FAILURE';

export const fetchGraphConditionForecast = baseFetchGraphForecast(
  FETCH_GRAPH_CONDITION_FORECAST,
  FETCH_GRAPH_CONDITION_FORECAST_SUCCESS,
  FETCH_GRAPH_CONDITION_FORECAST_FAILURE,
  fetchRegionalConditionForecast,
);

export const FETCH_GRAPH_SURF_FORECAST = 'FETCH_GRAPH_SURF_FORECAST';
export const FETCH_GRAPH_SURF_FORECAST_SUCCESS = 'FETCH_GRAPH_SURF_FORECAST_SUCCESS';
export const FETCH_GRAPH_SURF_FORECAST_FAILURE = 'FETCH_GRAPH_SURF_FORECAST_FAILURE';

export const fetchGraphSurfForecast = () =>
  baseFetchGraphForecast(
    FETCH_GRAPH_SURF_FORECAST,
    FETCH_GRAPH_SURF_FORECAST_SUCCESS,
    FETCH_GRAPH_SURF_FORECAST_FAILURE,
    fetchSurfForecast,
  );

export const FETCH_GRAPH_LIVE_SURF_FORECAST = 'FETCH_GRAPH_LIVE_SURF_FORECAST';
export const FETCH_GRAPH_LIVE_SURF_FORECAST_SUCCESS = 'FETCH_GRAPH_LIVE_SURF_FORECAST_SUCCESS';
export const FETCH_GRAPH_LIVE_SURF_FORECAST_FAILURE = 'FETCH_GRAPH_LIVE_SURF_FORECAST_FAILURE';

export const fetchGraphLiveSurfForecast = () =>
  baseFetchGraphForecast(
    FETCH_GRAPH_LIVE_SURF_FORECAST,
    FETCH_GRAPH_LIVE_SURF_FORECAST_SUCCESS,
    FETCH_GRAPH_LIVE_SURF_FORECAST_FAILURE,
    fetchLiveSurfForecast,
  );

export const FETCH_GRAPH_SWELLS_FORECAST = 'FETCH_GRAPH_SWELLS_FORECAST';
export const FETCH_GRAPH_SWELLS_FORECAST_SUCCESS = 'FETCH_GRAPH_SWELLS_FORECAST_SUCCESS';
export const FETCH_GRAPH_SWELLS_FORECAST_FAILURE = 'FETCH_GRAPH_SWELLS_FORECAST_FAILURE';

export const fetchGraphSwellsForecast = () =>
  baseFetchGraphForecast(
    FETCH_GRAPH_SWELLS_FORECAST,
    FETCH_GRAPH_SWELLS_FORECAST_SUCCESS,
    FETCH_GRAPH_SWELLS_FORECAST_FAILURE,
    fetchSwellsForecast,
  );

export const FETCH_GRAPH_TIDE_FORECAST = 'FETCH_GRAPH_TIDE_FORECAST';
export const FETCH_GRAPH_TIDE_FORECAST_SUCCESS = 'FETCH_GRAPH_TIDE_FORECAST_SUCCESS';
export const FETCH_GRAPH_TIDE_FORECAST_FAILURE = 'FETCH_GRAPH_TIDE_FORECAST_FAILURE';

export const fetchGraphTideForecast = baseFetchGraphForecast(
  FETCH_GRAPH_TIDE_FORECAST,
  FETCH_GRAPH_TIDE_FORECAST_SUCCESS,
  FETCH_GRAPH_TIDE_FORECAST_FAILURE,
  fetchTideForecast,
);

export const RESET_GRAPH_WEATHER_FORECAST = 'RESET_GRAPH_WEATHER_FORECAST';
export const FETCH_GRAPH_WEATHER_FORECAST = 'FETCH_GRAPH_WEATHER_FORECAST';
export const FETCH_GRAPH_WEATHER_FORECAST_SUCCESS = 'FETCH_GRAPH_WEATHER_FORECAST_SUCCESS';
export const FETCH_GRAPH_WEATHER_FORECAST_FAILURE = 'FETCH_GRAPH_WEATHER_FORECAST_FAILURE';

export const fetchGraphWeatherForecast = baseFetchGraphForecast(
  FETCH_GRAPH_WEATHER_FORECAST,
  FETCH_GRAPH_WEATHER_FORECAST_SUCCESS,
  FETCH_GRAPH_WEATHER_FORECAST_FAILURE,
  fetchWeatherForecast,
);

export const FETCH_GRAPH_SUNLIGHT_TIMES = 'FETCH_GRAPH_SUNLIGHT_TIMES';
export const FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS = 'FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS';
export const FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE = 'FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE';

export const fetchGraphSunlightTimes = baseFetchGraphForecast(
  FETCH_GRAPH_SUNLIGHT_TIMES,
  FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS,
  FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE,
  fetchSunlightTimes,
);

export const FETCH_GRAPH_WIND_FORECAST = 'FETCH_GRAPH_WIND_FORECAST';
export const FETCH_GRAPH_WIND_FORECAST_SUCCESS = 'FETCH_GRAPH_WIND_FORECAST_SUCCESS';
export const FETCH_GRAPH_WIND_FORECAST_FAILURE = 'FETCH_GRAPH_WIND_FORECAST_FAILURE';

export const fetchGraphWindForecast = baseFetchGraphForecast(
  FETCH_GRAPH_WIND_FORECAST,
  FETCH_GRAPH_WIND_FORECAST_SUCCESS,
  FETCH_GRAPH_WIND_FORECAST_FAILURE,
  fetchWindForecast,
);

export const FETCH_GRAPH_RATING_FORECAST = 'FETCH_GRAPH_RATING_FORECAST';
export const FETCH_GRAPH_RATING_FORECAST_SUCCESS = 'FETCH_GRAPH_RATING_FORECAST_SUCCESS';
export const FETCH_GRAPH_RATING_FORECAST_FAILURE = 'FETCH_GRAPH_RATING_FORECAST_FAILURE';

export const fetchGraphRatingForecast = baseFetchGraphForecast(
  FETCH_GRAPH_RATING_FORECAST,
  FETCH_GRAPH_RATING_FORECAST_SUCCESS,
  FETCH_GRAPH_RATING_FORECAST_FAILURE,
  fetchRatingForecast,
);

export const RESET_GRAPH_CONSISTENCY_FORECAST = 'RESET_GRAPH_CONSISTENCY_FORECAST';
export const FETCH_GRAPH_CONSISTENCY_FORECAST = 'FETCH_GRAPH_CONSISTENCY_FORECAST';
export const FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS = 'FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS';
export const FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE = 'FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE';

export const fetchGraphConsistencyForecast = baseFetchGraphForecast(
  FETCH_GRAPH_CONSISTENCY_FORECAST,
  FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS,
  FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE,
  fetchConsistencyForecast,
);

export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST = 'FETCH_GRAPH_SWELL_SPECTRA_FORECAST';
export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS =
  'FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS';
export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE =
  'FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE';
export const RESET_GRAPH_SWELL_SPECTRA_FORECAST = 'RESET_GRAPH_SWELL_SPECTRA_FORECAST';

export const fetchGraphSwellSpectraForecast = baseFetchGraphForecast(
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE,
  fetchSwellSpectraForecast,
);
